var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-auto mb-3", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "mew-heading-2 pt-8 pb-7 text-center" }, [
          _vm._v(" " + _vm._s(_vm.titleDetails.title) + " "),
        ]),
        _c(
          "div",
          { staticClass: "upload-container rounded pa-3 pa-sm-8" },
          [
            !_vm.uploading && !_vm.uploaded
              ? _c(
                  "div",
                  {
                    class: [
                      "d-flex align-center flex-column text-center",
                      _vm.skipped ? "mb-12" : "mb-6",
                    ],
                  },
                  [
                    _c("img", {
                      staticClass: "mb-7 mx-auto d-block",
                      attrs: {
                        src: require("@/assets/images/icons/icon-shield.svg"),
                        alt: "Shield",
                        height: "50",
                      },
                    }),
                    _c("div", { staticClass: "mew-heading-3" }, [
                      _vm._v(" " + _vm._s(_vm.titleDetails.subtitle) + " "),
                    ]),
                    !_vm.skipped
                      ? _c("span", { staticClass: "mew-body mt-2" }, [
                          _vm._v(" " + _vm._s(_vm.titleDetails.desc) + " "),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.uploading && !_vm.uploaded
              ? _c("v-progress-linear", {
                  staticClass: "mb-12",
                  attrs: { indeterminate: "" },
                })
              : _vm._e(),
            _vm.uploaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-center d-flex align-center flex-column font-weight-bold mb-6",
                  },
                  [
                    _vm.hasError
                      ? _c("img", {
                          staticClass: "mb-7 mx-auto d-block",
                          attrs: {
                            src: require("@/assets/images/icons/icon-shield-crossed.svg"),
                            alt: "Shield",
                            height: "50",
                          },
                        })
                      : _vm._e(),
                    !_vm.hasError
                      ? _c("img", {
                          staticClass: "mb-7 mx-auto d-block",
                          attrs: {
                            src: require("@/assets/images/icons/icon-shield-checked.svg"),
                            alt: "Shield",
                            height: "50",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: [
                          "mew-heading-3",
                          _vm.hasError ? "redPrimary--text" : "textDark--text",
                        ],
                      },
                      [_vm._v(" " + _vm._s(_vm.uploadedText.title) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "mt-2 mew-body",
                          _vm.hasError
                            ? "redPrimary--text"
                            : "orangePrimary--text",
                        ],
                        staticStyle: { "max-width": "420px" },
                      },
                      [_vm._v(" " + _vm._s(_vm.uploadedText.desc) + " ")]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "greyLight mb-8 input-container d-block d-sm-flex rounded align-center justify-space-between pa-5",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("mew-icon", {
                      attrs: { "img-height": 32, "icon-name": "keystore" },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ml-3 file-name-container mew-heading-4 textLight--text truncate",
                      },
                      [_vm._v(" " + _vm._s(_vm.inputTitle) + " ")]
                    ),
                    _vm.uploaded && !_vm.hasError
                      ? _c(
                          "v-icon",
                          { attrs: { size: "16", color: "greenPrimary" } },
                          [_vm._v(" mdi-checkbox-marked-circle ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("mew-button", {
                  staticClass: "my-2",
                  attrs: {
                    "btn-size": "small",
                    title: "Browse...",
                    "btn-style": "outline",
                    "has-full-width": _vm.$vuetify.breakpoint.xs,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$refs.inputUpload.click()
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  ref: "inputUpload",
                  attrs: { type: "file", accept: ".json" },
                  on: { change: _vm.upload },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.newEth2Address && _vm.uploaded
          ? _c("div", { staticClass: "overlayBg rounded pa-5 mt-2" }, [
              _c("div", { staticClass: "mew-heading-3 mb-3" }, [
                _vm._v("Your Eth2 Address"),
              ]),
              _c("div", { staticClass: "break-word mew-address" }, [
                _vm._v(" " + _vm._s(_vm.newEth2Address) + " "),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "mt-7 d-flex flex-column-reverse flex-md-row align-center justify-center",
          },
          [
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: "Back",
                "btn-style": "outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBack.apply(null, arguments)
                },
              },
            }),
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: "Complete: Create another",
                disabled: !_vm.newEth2Address || !_vm.uploaded || _vm.hasError,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBack.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }